<template lang="pug">
  l-marker(:lat-lng='init', ref="marker", :visible="visible", :duration="1000",
    :options="{ item, zIndexOffset, ...options.marker }" :icon="defaultIcon")
    l-popup(:options="options.tooltip", v-if="activeObject",
      @add="activeMarker = true; showPopup()",
      @remove="activeMarker = false; hidePopup()")
      .popover-wrapper.popover-body
        .popover-container(v-if="popupShow")
          .popover-title.d-flex.p-2
            b-icon.mr-2(icon="reception4", :variant="switchStateColor(activeObject.color)", font-scale="1",
            :title='`${$t("popoverobject.connect")}: ${activeObject.lastConnectionTime.toLocaleString()} (${$t("popoverobject.time")} ${activeObject.lastPointTime.toLocaleString()})`')
            DynamicFromNow(tag="div" :value="activeObject.lastConnectionTime")
          .popover-title.d-flex.p-2
            b-icon.mr-2(icon="compass", :variant="switchStateColor(activeObject.color)", font-scale="1",
            :title='`${$t("popoverobject.coords")}: ${activeObject.lastValidTime.toLocaleString()}`')
            DynamicFromNow(tag="div" :value="activeObject.lastValidTime")
          .popover-title.d-flex.p-2
            b-icon.mr-2(icon="info-circle", :variant="switchStateColor(activeObject.color)", font-scale="1",
            :title='`${$t("popoverobject.state")}: ${stateName(activeObject.state)} ${activeObject.stateBeginTime.toLocaleString()}`')
            div.mr-1 {{stateName(activeObject.state)}}
            DynamicFromNow(:value="activeObject.stateBeginTime", :state="true")
          .d-flex.p-2
            b-icon.mr-2(icon="geo-alt-fill", variant="secondary", font-scale="1", :title="$t('popoverobject.address')")
            b-overlay.d-inline-block.w-100(:show="!getReversedAddress(item)", rounded, opacity='0.6', spinner-small, spinner-variant='primary')
              span {{ getReversedAddress(item) }}
          .d-flex.popover-block.sensor
            .d-flex.align-items-center.justify-content-center.p-2.popover-speed(:title="$t('popoverobject.speed')") {{activeObject.speed === null ? '---' : `${Math.round(activeObject.speed)} ${$t("popoverobject.km")}`}}
            .d-flex.align-items-center.justify-content-center.p-2.popover-speed(:title="$t('popoverobject.altitude')") {{activeObject.alt === null ? '---' : Math.round(activeObject.alt) + ` ${$t("popoverobject.m")}.`}}
            .d-flex.align-items-center.justify-content-center.p-2.popover-speed(:title="$t('popoverobject.satellites')")
              b-icon.mr-2(icon="globe", variant="secondary", font-scale="2")
              span {{activeObject.satelliteCount}}
          .d-flex.p-2(v-if="activeObject.sensors.length > 0")
            div(v-for="sensor in sensors" :key="sensor.Id", style="width: 50%;")
              .d-flex.mr-2
                span.mr-2 {{ sensor.Name }}
                b  {{ sensor.Value }} {{ sensor.Unit }}
          .d-flex.p-2
            b-button(variant="light", size="sm", @click="$emit('click', $event)", :title="$t('popoverobject.search')")
              b-icon(icon="search", variant="dark", font-scale="1")
    l-icon(v-if="iconForce", :options="{ ...iconId, iconUrl: icon }", :icon-size="dynamicSize", :icon-anchor="dynamicAnchor")
      .d-flex.flex-column.align-items-center.le-marker(:class="{ active }")
        template
          img.img-fluid.le-marker--image(v-if="icon" :src="icon", :class="isLastTimeWasOnline ? '' : 'img-blurry'")
          b-spinner(v-else, variant="info", :class="isLastTimeWasOnline ? '' : 'img-blurry'")
          div(v-if="item?.reactive?.color === 'Green'" class="marker-direction-container")
            b-icon(
              v-if="activeObject && activeObject.head !== null && settingsMap.showObjectDirection",
              icon="caret-up-fill",
              :shift-h="horizontally",
              :shift-v="vertically",
              variant="info",
              :rotate="activeObject.head",
              font-scale="1")
              //- Для тестирования стрелки
              //- :rotate="activeObject.head + dOffset",
        .pt-1(v-if="!settingsMap.showObjectDirection && settingsMap.showObjectName")
        .d-flex.justify-content-center.le-marker--name-wrap(v-if="settingsMap.showObjectName")
          span.le-marker--name {{ item.data.Name }}
</template>
<script>
import { mapGetters } from 'vuex';
// import LMovingMarker from '~/components/Vue2LeafletMovingmarker';
import imageGenerator from '~/plugins/handlers/imageGenerator';
import DynamicFromNow from '~/components/VueMomentFromNow';

const DIRECTION_OFFSET = 3; // Смещение стрелки от круга в пикселях (точнее, от элемента div иконки маркера)

function lerp (start, end, t) {
  return start * (1 - t) + end * t;
}

export default {
  name: 'LeMainMarker',
  components: { DynamicFromNow },
  props: {
    item: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      default: true,
      required: false,
    },
    active: {
      type: Boolean,
      required: false,
    },
  },
  data () {
    return {
      iconId: {
        iconId: 'icon-object',
      },
      init: [this.item.reactive.lat || 0, this.item.reactive.lng || 0],
      p: [this.item.reactive.lat || 0, this.item.reactive.lng || 0],
      force: true,
      timeout: null,
      icon: null,
      iconForce: true,
      options: {
        marker: {
          riseOnHover: true,
        },
        tooltip: {
          autoPan: false,
          className: 'mapobj-tooltype',
          closeButton: false,
          keepInView: true,
          offset: this.$L.point(0, 0),
        },
        polyline: {
          weight: 2,
          dashArray: '8 10',
          color: '#17a2b8',
        },
      },
      activeObject: this.item.reactive,
      activeMarker: false,
      popupShow: false,
      popupShowTimer: null,
      polyline: null,
      map: global.mainMap,
      defaultIcon: this.$L.divIcon({ className: 'my-div-icon' }),
      directionOffset: DIRECTION_OFFSET + 6, // 6 - смещение стрелки относительно самой себя
      // dOffset: 0,
      // dOffsetInterval: null,
    };
  },
  computed: {
    ...mapGetters('preferences', { settingsBase: 'settingsAUnit' }),
    ...mapGetters('preferences', ['settingsMap']),
    ...mapGetters('objects', ['getReversedAddress']),
    sensors () {
      return this.prepareSensors(this.activeObject.sensors ?? [], this.item.data.Sensors ?? []);
    },
    zIndexOffset () {
      let index = 20;
      if (this.active) {
        index = 300;
      }

      return index;
    },
    dynamicSize () {
      const iconSize = (this.settingsBase.monitoringIconSize - 0.2) * 90;
      return [iconSize, iconSize];
    },
    dynamicAnchor () {
      const iconAnchor = (this.settingsBase.monitoringIconSize - 0.2) * 90 / 2;
      return [iconAnchor, iconAnchor];
    },
    v () {
      return this.item.v;
    },
    horizontally () {
      // Серьезно, не понимаю, зачем коэффициент 4/3, но без него едет куда не надо
      const radius = (this.dynamicSize[0] * 1.33 + 12) / 2 + DIRECTION_OFFSET;
      return (radius * Math.cos((this.activeObject.head - 90) * Math.PI / 180 * -1)) + 1; // +1 для визуального центрирования
    },
    vertically () {
      const radius = (this.dynamicSize[0] * 1.33 + 12) / 2 + DIRECTION_OFFSET;
      return (radius * Math.sin((this.activeObject.head - 90) * Math.PI / 180 * -1)) + radius - DIRECTION_OFFSET + 3; // +3 для визуального центрирования
    },
    latLngs () {
      const points = [];
      this.item.reactive.lastPoints.forEach((x) => {
        if (x.Latitude !== 0 && x.Longitude !== 0) {
          points.push(new this.$L.LatLng(x.Latitude, x.Longitude));
        }
      });
      return points;
    },
    isLastTimeWasOnline () {
      return !this.settingsMap.blurOutdatedMarkers ||
             (this.item?.reactive?.color === 'Green') ||
             (this.item?.reactive?.lastConnectionTime instanceof Date && ((this.item.reactive.lastConnectionTime.getTime() + 86400000 * 2) > Date.now()));
    },
  },
  watch: {
    v (n, o) {
      if (n !== o) {
        // this.redraw();
        const nll = [this.item.reactive.lat, this.item.reactive.lng];
        const oll = this.p;
        if (oll[0] !== nll[0] || oll[1] !== nll[1]) {
          try {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              let start = null;
              const duration = 1000;
              const step = (timestamp) => {
                if (!start) {
                  start = timestamp;
                }
                const progress = timestamp - start;
                let t = progress / duration;
                t = t <= 1 ? t : 1;

                this.$refs.marker.setLatLng([lerp(oll[0], nll[0], t), lerp(oll[1], nll[1], t)]);
                if (progress < duration) {
                  requestAnimationFrame(step);
                }
              };
              if (this.$refs.marker) {
                if (this.settingsMap.groupingObjects) {
                  requestAnimationFrame(step);
                } else {
                  this.$refs.marker.setLatLng(nll);
                }
              }
            }, 3000);
          } catch (error) {
            console.error(nll, this.item);
          }
        }

        this.p = nll;
        this.createPolyLine();
      }
    },
    // eslint-disable-next-line object-shorthand
    'settingsMap.needFootprints' () {
      this.createPolyLine();
    },
    // eslint-disable-next-line object-shorthand
    async 'activeObject.address' () {
      await this.getAdress();
    },
    async activeMarker () {
      await this.getAdress();
    },
  },
  created () {
    this.getIcon();
    // if (process.env.NODE_ENV === 'development') {
    //   if (this.item?.reactive?.color !== 'Green' && (this.item?.reactive?.lastConnectionTime instanceof Date && ((this.item.reactive.lastConnectionTime.getTime() + 86400000 * 2) > Date.now()))) {
    //     console.info(`%cОбъект "${this.item.name}" был последние двое суток на связи`, 'color: orange');
    //   }
    // }
    // this.dOffsetInterval = setInterval(() => { this.dOffset = (this.dOffset + 2) % 360; }, 4);
  },
  mounted () {
    // this.getIcon().then(() => {
    this.$nextTick().then(() => {
      this.$refs.marker.mapObject.on('add', () => {
        this.redrawIcon();
      });
    });
    this.createPolyLine();
    // });
  },
  destroyed () {
    if (this.polyline) {
      this.polyline.removeFrom(this.map);
      this.polyline = null;
      // clearInterval(this.dOffsetInterval);
    }
  },
  methods: {
    prepareSensors (sensors, units) {
      const calcValue = ({ Value }) => (Number.isNaN(Value) ? Value : Number(Value).toFixed(2));
      return sensors.map(sensor => ({ ...sensor, Value: calcValue(sensor), Unit: units.find(measurement => measurement.Name === sensor.Name)?.Unit }));
    },
    redraw () {
      this.force = false;
      this.$nextTick().then(() => {
        this.force = true;
      });
    },
    redrawIcon () {
      this.iconForce = false;
      this.$nextTick().then(() => {
        this.iconForce = true;
      });
    },
    async imGen (_icon) {
      const file = `${process.env.API_ADDRESS}/v1/GetIconImage(Id='${_icon.IconFile}')`;
      const image = await imageGenerator({
        file,
        size: _icon.Size,
        offset: [_icon.X, _icon.Y],
      });
      return image;
    },
    async getIcon () {
      const image = await this.imGen(this.item.data.Icon);
      // const max = 1000;
      // const min = 200;

      // this.icon = image;
      this.$nextTick(() => {
        this.icon = image;
      });
      // setTimeout(() => {
      //   this.icon = image;
      // }, Math.floor(Math.random() * (max - min + 1) + min));
      this.redrawIcon();
    },
    switchStateColor (value) {
      let color;
      switch (value) {
        case 'Green':
          color = 'success';
          break;
        case 'Yellow':
          color = 'warning';
          break;
        case 'Red':
          color = 'danger';
          break;
        default:
          color = 'secondary';
          break;
      }
      return color;
    },
    stateName (state) {
      let value;
      switch (state) {
        case 'Parking':
          value = this.$t('popoverobject.parking');
          break;
        case 'LostCoords':
          value = this.$t('popoverobject.lostcoords');
          break;
        case 'Ride':
          value = this.$t('popoverobject.ride');
          break;
        default:
          value = this.$t('popoverobject.lostconnect');
          break;
      }
      return value;
    },
    createPolyLine () {
      if (this.settingsMap.needFootprints && this.latLngs.length > 0) {
        if (this.polyline) {
          this.polyline.setLatLngs(this.latLngs);
        } else {
          this.polyline = this.$L.polyline(this.latLngs, this.options.polyline).addTo(this.map);
        }
      } else if (this.polyline) {
        this.polyline.removeFrom(this.map);
        this.polyline = null;
      }
    },
    async getAdress () {
      if (this.activeMarker && this.item.reactive.address === null) {
        await this.$store.dispatch('objects/updateAddress', { id: this.item.id, lat: this.activeObject.lat, lon: this.activeObject.lng, not: this.$t('notaddress') });
      }
    },
    showPopup () {
      this.popupShow = true;
      clearTimeout(this.popupShowTimer);
    },
    hidePopup () {
      this.popupShowTimer = setTimeout(() => { this.popupShow = false; }, 200);
    },
  },
};
</script>

<style lang="stylus">
  .mapobj-tooltype {
    // margin-bottom: 40px;
    &--body {
      display: flex;
      height: 100%;
    }
    .leaflet-popup {
      &-tip {
        display: none;
        &-container {
          left: calc(50% - 12px);
          bottom: calc(-.4rem - 1px);
          position:absolute;
          display:block;
          width:1rem;
          height:.5rem;
          margin:0 .3rem
          &:after,
          &:before {
            position:absolute;
            display:block;
            content:"";
            border-color:transparent;
            border-style:solid
          }
          &:before {
            bottom: 0;
            border-width: .5rem .5rem 0;
            border-top-color:
            rgba(0,0,0,.25);
          }
          &:after {
            bottom: 1px;
            border-width: .5rem .5rem 0;
            border-top-color: #fff;
          }
        }
      }
      &-content {
        margin: 0;
        color: #212529;
        min-width: 380px;
        min-height: 100px;
        width: 100%;
        .popover-body {
          padding: 0 0;
        }
        &-wrapper {
          display: flex;
          // font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5;
          text-align: left;
          text-decoration: none;
          text-shadow: none;
          text-transform: none;
          letter-spacing: normal;
          word-break: normal;
          word-spacing: normal;
          white-space: normal;
          line-break: auto;
          font-size: .875rem;
          word-wrap: break-word;
          background-clip: padding-box;
          border: 1px solid rgba(0,0,0,.2);
          padding: 0 0;
          position: relative;
          min-width: 380px;
          min-height: 100px;
          z-index: 2;
          margin-left: 15px;
          border-radius: 3px;
          box-shadow: 0 3px 4px 2px rgba(0, 0, 0, 0.2);
          background-color: #fff;
          overflow: hidden;
        }
      }
    }
  }
</style>
<style lang="stylus" scoped>
.img-fluid {
  width: 100%;
  height: auto;
}
.img-blurry {
  filter: blur(3px);
}
.shade {
  opacity: .2;
  transition: opacity .3s;
  &:hover {
    opacity: 1;
  }
}
.alarm-box {
  z-index: 1;
  pointer-events: none;
  position: absolute;
  top: -15px;
  left: -15px;
  right: -15px;
  bottom: -15px;
}
.large-violation {
  width: 4rem;
  height: 4rem;
}
.leaflet-container
  .le-marker {
    z-index: 2;
    position: relative;
    &:hover, &.active {
      .le-marker--name {
        background-color: #00000088;
        color: #fff;
        text-shadow: none;
        font-weight: 600;
      }
    }
    &.active {
      .le-marker--image {
        filter: drop-shadow(0 0 2px #000);
      }
      &:hover {
        .le-marker--image {
          filter: drop-shadow(0 0 3px #000);
        }
        .le-marker--name {
          background-color: #000000aa;
        }
      }
    }
    &.violation {
      .le-marker--image {
        filter: drop-shadow(0 0 8px red);
      }
    }
    &--image {
      max-width: 150% !important;
    }
    &--name {
      --map-obj-textStroke: #fff;
      color: #e60000;
      max-width: 100%;
      text-shadow: -0 -1px 0 var(--map-obj-textStroke),0 -1px 0 var(--map-obj-textStroke),-0 1px 0 var(--map-obj-textStroke),0 1px 0 var(--map-obj-textStroke),-1px -0 0 var(--map-obj-textStroke),1px -0 0 var(--map-obj-textStroke),-1px 0 0 var(--map-obj-textStroke),1px 0 0 var(--map-obj-textStroke),-1px -1px 0 var(--map-obj-textStroke),1px -1px 0 var(--map-obj-textStroke),-1px 1px 0 var(--map-obj-textStroke),1px 1px 0 var(--map-obj-textStroke),-1px -1px 0 var(--map-obj-textStroke),1px -1px 0 var(--map-obj-textStroke),-1px 1px 0 var(--map-obj-textStroke),1px 1px 0 var(--map-obj-textStroke);
      border-radius: 3px;
      padding: 0 3px;
      &-wrap {
        width: 220px;
        pointer-events: none;
        position: absolute;
        bottom: 0;
        transform: translateY(100%);
        text-align: center;
      }
    }
  }
.popover-wrapper {
  .popover-title {
    display: flex;
    justify-content: flex-start;
    background-color: #f2f2f2;
    font-size: 14px;
    width: 100%;
  }
  .popover-block.sensor {
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    display: flex;
    justify-content: space-between;
    padding: 0;
    div {
    text-align: center;
    width: 25%;
    padding: 10px 0;
    }
  }
  .popover-speed {
    font-size: 16px;
    font-weight: bold;
    color: #00a1b3;
    border-right: 1px solid #f2f2f2;
  }
}
.marker-direction-container {
  position: relative;
  height: 0;
  width: 1em;

  & > svg {
    position: absolute;
    top: 0;
    width: 1em;
    height: 1em;
  }
}
</style>
