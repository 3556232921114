const templatesApi = '/v1/TrackerCommandTaskTemplates/';
const tasksApi = '/v1/TrackerCommandTasks/';
const eventsApi = '/v1/TrackerCommandTaskEvents/';

function templateRestMapper (obj) {
  return {
    id: obj.Id,
    name: obj.Name,
    commandText: obj.CommandText,
    objectIds: obj.ObjectIds,
    createDate: obj.CreateDate && new Date(obj.CreateDate * 1000),
    updateDate: obj.UpdateDate && new Date(obj.UpdateDate * 1000),
    deleteDate: obj.DeleteDate && new Date(obj.DeleteDate * 1000),
    timeout: obj.Timeout,
    accountingUnitId: obj.AccountingUnitId,
  };
}

function eventMapper (ev, tasks) {
  return {
    ...ev,
    CancelTime: ev.CancelTime && new Date(ev.CancelTime * 1000),
    Timestamp: ev.Timestamp && new Date(ev.Timestamp * 1000),
    Tasks: tasks.map(x => ({
      ...x,
      BestBefore: x.BestBefore && new Date(x.BestBefore * 1000),
      CreateDate: x.CreateDate && new Date(x.CreateDate * 1000),
      UpdateDate: x.UpdateDate && new Date(x.UpdateDate * 1000),
    })),
    CommandText: tasks[0]?.CommandText ?? '<Ошибка получения команды>',
  };
}

export const state = () => ({
  templatesArray: [],
  templatesMap: new Map(), // Задумывался как быстрый доступ по идентификатору
  events: {},
});

export const mutations = {
  addTemplate (state, item) {
    if (state.templatesMap.has(item.id)) {
      const id = state.templatesArray.findIndex(x => x.id === item.id);
      // eslint-disable-next-line no-param-reassign
      item.events = item.events ?? state.templatesArray[id]?.events ?? [];
      state.templatesArray.splice(id, 1, item);
    } else {
      // eslint-disable-next-line no-param-reassign
      item.events = item.events ?? [];
      state.templatesArray.unshift(item);
    }
    state.templatesMap.set(item.id, item);
  },
  deleteTemplate (state, id) {
    if (state.templatesMap.has(id)) {
      state.templatesMap.delete(id);
      const i = state.templatesArray.findIndex(x => x.id === id);
      state.templatesArray.splice(i, 1);
    }
  },
  setEvents (state, events) {
    events.forEach((event) => {
      state.events[event.Id] = event;
    });
  },
  addEventToTemplate (state, { id, event }) {
    state.templatesMap.get(id)?.events?.unshift?.(event);
  },
};

function arraySplit (array, predicate = () => true) {
  const arr1 = [];
  const arr2 = [];
  array.forEach((item) => {
    (predicate(item) ? arr1 : arr2).push(item);
  });
  return [arr1, arr2];
}

export const getters = {
  templates: state => state.templatesArray,
  templatesMap: state => state.templatesMap,
};

export const actions = {
  refreshTemplates ({ commit }) {
    const templates = this.$api.$get(templatesApi);
    const events = this.$api.$get(eventsApi);
    const tasks = this.$api.$get(tasksApi);
    return Promise.all([templates, events, tasks]).then(([data, edata, tdata]) => {
      let ee = edata.value.reverse();
      let tt = tdata.value.reverse();
      let tEvents;
      let tTasks;
      (data?.value || []).reverse().map(templateRestMapper).forEach((el) => {
        // eslint-disable-next-line no-param-reassign
        el.events = [];
        [tEvents, ee] = arraySplit(ee, x => x.TemplateId === el.id);
        tEvents.forEach((ev) => {
          [tTasks, tt] = arraySplit(tt, x => x.TaskEventId === ev.Id);
          el.events.push(eventMapper(ev, tTasks));
        });
        commit('setEvents', el.events);
        commit('addTemplate', el);
      });
      return true;
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error} : Ошибка получения шаблонов задач`,
        type: 'danger',
      }, { root: true });
      return false;
    });
  },
  getTemplate ({ commit }, id) {
    return this.$api.$get(`${templatesApi}${id}`).then((data) => {
      if (data?.Id) {
        const result = templateRestMapper(data);
        commit('addTemplate', result);
        return result;
      }
      commit('notify/addNotify', {
        massage: 'Ошибка: Некорректный ответ от сервера',
        type: 'danger',
      }, { root: true });
      return false;
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error} : Ошибка получения шаблона задачи`,
        type: 'danger',
      }, { root: true });
      return false;
    });
  },
  createTemplate ({ commit }, { name, commandText, objectIds, timeout, accountingUnitId }) {
    const data = {
      name,
      commandText,
      objectIds,
      timeout: timeout || 0,
      accountingUnitId,
    };
    return this.$api.$post(templatesApi, data).then((result) => {
      commit('addTemplate', templateRestMapper(result));
      return true;
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка создания шаблона задачи`,
        type: 'danger',
      }, { root: true });
      return false;
    });
  },
  updateTemplate ({ commit, dispatch }, { id, name, commandText, objectIds, timeout, accountingUnitId }) {
    const data = {
      id,
      name,
      commandText,
      objectIds,
      timeout: timeout || 0,
      accountingUnitId,
    };
    return this.$api.$patch(`${templatesApi}${id}`, data).then(() => dispatch('getTemplate', id)).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка редактирования шаблона задачи`,
        type: 'danger',
      }, { root: true });
      return false;
    });
  },
  deleteTemplate ({ commit }, id) {
    return this.$api.$delete(`${templatesApi}${id}`).then(() => {
      commit('deleteTemplate', id);
      return true;
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка удаления шаблона задачи`,
        type: 'danger',
      }, { root: true });
      return false;
    });
  },
  runTemplate ({ commit, dispatch }, id) {
    return this.$api.$post(`${templatesApi}${id}/Run`).then((eventId) => {
      const event = dispatch('getEvent', eventId);
      const tasks = dispatch('getTasksByEventId', eventId);
      Promise.all([event, tasks]).then(([ev, t]) => {
        const tEvent = eventMapper(ev, t);
        commit('addEventToTemplate', { id, event: tEvent });
        commit('setEvents', [tEvent]);
      });
      return true;
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error} : Ошибка запуска шаблона задачи`,
        type: 'danger',
      }, { root: true });
      return false;
    });
  },
  getTasksByEventId ({ commit }, eventId) {
    return this.$api.$get(`${tasksApi}?$filter=TaskEventId eq ${eventId}`).then(data => data.value.reverse()).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error} : Ошибка получения задач по событиям`,
        type: 'danger',
      }, { root: true });
      return false;
    });
  },
  getEvent ({ commit }, id) {
    return this.$api.$get(`${eventsApi}${id}`).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error} : Ошибка получения шаблонов задач`,
        type: 'danger',
      }, { root: true });
      return false;
    });
  },
};
