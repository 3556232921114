export const state = () => ({
  shared: [],
});

export const mutations = {
  setSharedObjects (state, item) {
    state.shared = item;
  },
};

export const getters = {
  shared: state => state.shared || [],
};

export const actions = {
  async getSharedObjects ({ commit }, accountingUnit = null) {
    await this.$api.$get(`/v1/SharedObjectsGroups?$expand=Objects($select=Id)&$select=Id,Name${accountingUnit ? `&$filter=AccountingUnitId eq ${accountingUnit}` : ''}&$orderby=Name`).then((data) => {
      if (data && data.value) {
        commit('setSharedObjects', data.value);
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка получения Общих групп и их объектов`,
        type: 'danger',
      }, { root: true });
    });
    return true;
  },
  getSharedObjectList ({ commit }, accountingUnit = null) {
    return this.$api.$get(`/v1/SharedObjectsGroups?$expand=Objects($select=Id)&$select=Id,Name${accountingUnit ? `&$filter=AccountingUnitId eq ${accountingUnit}` : ''}&$orderby=Name`).then(data => data?.value || [])
    .catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка получения Общих групп и их объектов`,
        type: 'danger',
      }, { root: true });
    }).finally(() => []);
  },
  updatedSharedObjectsGroups ({ dispatch, commit }, params) {
    const { id, group, accountingUnit } = params;
    this.$api.patch(`/v1/SharedObjectsGroups(${id})`, group).then((data) => {
      if (data) {
        dispatch('getSharedObjects', accountingUnit);
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка изменения Общей группы объектов`,
        type: 'danger',
      }, { root: true });
    });
  },
  createdSharedObjectsGroups ({ dispatch, commit }, params) {
    const { group, accountingUnit } = params;
    this.$api.post('/v1/SharedObjectsGroups', group).then((data) => {
      if (data) {
        dispatch('getSharedObjects', accountingUnit);
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка создания Общей группы объектов`,
        type: 'danger',
      }, { root: true });
    });
  },
  deletedSharedObjectsGroups ({ dispatch, commit }, params) {
    const { id, accountingUnit } = params;
    this.$api.delete(`/v1/SharedObjectsGroups(${id})`).then((data) => {
      if (data) {
        dispatch('getSharedObjects', accountingUnit);
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка удаления Общей группы объектов`,
        type: 'danger',
      }, { root: true });
    });
  },

  addObjectsInGroups ({ dispatch, commit }, params) {
    const { id, objectsIds, accountingUnit } = params;
    this.$api.post('/v1/SharedObjectsGroups/AddObjects', { Id: id, ObjectIds: objectsIds }).then(() => {
      dispatch('getSharedObjects', accountingUnit);
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка добавления объектов в Общую группу`,
        type: 'danger',
      }, { root: true });
    });
  },

  deleteObjectsFromGroups ({ dispatch, commit }, params) {
    const { id, all, objectsIds, accountingUnit } = params;
    this.$api.post('/v1/SharedObjectsGroups/RemoveObjects', { Id: id, AllGroups: all, ObjectIds: objectsIds }).then(() => {
      dispatch('getSharedObjects', accountingUnit);
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка удаления объектов из Общей группы`,
        type: 'danger',
      }, { root: true });
    });
  },
};
