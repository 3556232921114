import ky from 'ky';
import Vue from 'vue';
import mqtt from 'vue-mqtt';
import {
  errorHelper,
  getFromStorage,
  setToStorage,
  removeItem,
} from '~/plugins/handlers/utils';
import { PERIODIC_SERVER_POLLING_EVENTS } from '../consts/eventTypes';

function isNull (val) {
  return [null, undefined].includes(val);
}

/* STORAGE */

export const state = () => ({
  token: null,
  refreshToken: null,
  sessionType: undefined,
  requireNewSession: false,
  ssid: undefined,
  data: null,
  error: null,
  apikey: null,
});

export const mutations = {
  apikey (state, apikey = null) {
    state.apikey = apikey;
    state.token = apikey;
    if (apikey) {
      this.$api.setToken(apikey, 'apikey');
    } else {
      this.$api.setToken(false);
    }
  },
  token (state, token = null) {
    state.token = token;
    if (token) {
      this.$api.setToken(token, 'Bearer');
    } else {
      this.$api.setToken(false);
    }
  },
  refreshToken (state, refreshToken = null) {
    state.refreshToken = refreshToken;
  },
  sessionType (state, sessionType = null) {
    state.sessionType = sessionType;
  },
  setRequireNewSession (state, value = false) {
    state.requireNewSession = value;
  },
  data (state, data = null) {
    state.data = data;
    if ((data && state.requireNewSession) || (data && !state.ssid)) {
      state.ssid = data.SessionId;
      this.$api.setHeader('SessionId', data.SessionId);
      state.data.isAdmin = data.Roles.includes('Administrator');
      state.data.isAdminAU = data.Roles.includes('AccountingUnitAdministrator');
      state.data.isCommandSender = data.Roles.includes('TrackerCommandSender');
    }
  },
  error (state, error = null) {
    state.error = error;
  },
};

export const getters = {
  apikeyIsNull: state => isNull(state.apikey),
  tokenIsNull: state => isNull(state.token),
  dataIsNull: state => isNull(state.data),
  requireNewSession: state => state.requireNewSession,
  // eslint-disable-next-line
  expireDate: state => state.data ? state.data.ExpireDate : null,
};

export const actions = {
  init ({ state, commit, dispatch }) {
    if (!state.apikey) {
      commit('token', getFromStorage('token'));
      commit('refreshToken', getFromStorage('refreshToken'));
      commit('sessionType', getFromStorage('sessionType'));

      let stop = false;

      this.$api.onRequest(async (request, options) => {
        if (stop) {
          if (!options.refresh) {
            await stop;
            request.headers.set('Authorization', `Bearer ${state.token}`);
          }
        }
      });

      this.$api.onResponse(async (request, options, response) => {
        if (response.status === 401) {
          let refrash = null;
          if (stop) {
            await stop;
            refrash = true;
          } else {
            stop = new Promise((resolve, reject) => {
              dispatch('refrash').then((result) => {
                if (result) {
                  refrash = true;
                  resolve(false);
                } else {
                  reject();
                }
              });
            });

            await stop;
            stop = false;
          }

          if (refrash) {
            request.headers.set('Authorization', `Bearer ${state.token}`);
            return ky(request);
          }
        }
        return undefined;
      });
    }

    this.$api.onError((error) => {
      if (error.statusCode === 401) {
        commit('error', 'Ошибка авторизации');
        dispatch('logout', !!state.apikey).then(() => {
          this.$router.push('/login/');
        });
      }
      return undefined;
    });

    const initSubscribers = () => {
      this.$eventBus.subscribe(
        PERIODIC_SERVER_POLLING_EVENTS.sessionIsOutdated,
        'userStore',
        () => commit('setRequireNewSession', true),
      );
    };

    initSubscribers();
  },
  login ({ commit }, { login, pass, _refreshToken, session }) {
    const searchParams = new URLSearchParams();
    if (login) {
      searchParams.set('grant_type', 'password');
      searchParams.set('username', login);
      searchParams.set('password', pass);
    } else if (_refreshToken) {
      searchParams.set('grant_type', 'refresh_token');
      searchParams.set('refresh_token', _refreshToken);
    }
    return this.$api.$post('/auth/token', searchParams, { refresh: !!_refreshToken }).then((data) => {
      if (data.access_token) {
        const { access_token: token, refresh_token: refreshToken } = data;

        const opList = [['token', token], ['refreshToken', refreshToken], ['sessionType', session]];
        opList.forEach(([name, op]) => {
          commit(name, op);
          setToStorage(name, op, session);
          removeItem(name, !session);
        });

        return true;
      }
      return false;
    }).catch(e => errorHelper(e));
  },

  refrash ({ state, dispatch }) {
    if (state.refreshToken && !state.apikey) {
      return dispatch('login', {
        session: state.sessionType,
        _refreshToken: state.refreshToken,
      });
    }

    dispatch('logout');
    return false;
  },

  logout ({ commit }, isApikey = false) {
    ['token', 'refreshToken', 'sessionType', 'data'].forEach((el) => {
      commit(el);
    });
    [true, false].forEach((el) => {
      removeItem('token', el);
      removeItem('refreshToken', el);
    });
    document.location.reload();
  },

  fetchData ({ state, commit, dispatch }, locator = false) {
    if (state.token) {
      if (locator && state.apikey) {
        // eslint-disable-next-line
        const apikeyStr = 'Apikey ' + state.apikey;
        this.$api.setHeader('Authorization', apikeyStr);
      }
      return this.$api.$get('/v1/account').then((data) => {
        if (data) {
          commit('data', data);
          commit('setRequireNewSession', false);
          try {
            if (global.$nuxt.$route.name !== 'mqtttest') {
              Vue.use(mqtt, process.env.MQTT_URL, {
                clientId: `Web3_${data.SessionId}`,
                clean: true,
                username: data.UserName,
                password: data.Secret,
              });
            }
          } catch (error) {
            commit('notify/addNotify', {
              massage: error,
              type: 'danger',
            }, { root: true });
          }
          return true;
        }
        return false;
      }).catch((error) => {
        commit('notify/addNotify', {
          massage: `Ошибка ${error.name} : Ошибка получения данных пользователя`,
          type: 'danger',
        }, { root: true });
      });
    }
    return false;
  },

  changePassword ({ commit }, { oldPass, newPass }) {
    return this.$api.$post('/auth/ChangePassword', {
      oldPassword: oldPass,
      newPassword: newPass,
    }).then(() => {
      commit('notify/addNotify', {
        massage: 'Пароль пользователя был изменен.',
        type: 'success',
      }, { root: true });
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка изменения пароля пользователя`,
        type: 'danger',
      }, { root: true });
      return false;
    });
  },
};
